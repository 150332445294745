.description {
  &-text {
    margin: $spacing-l 0;

    h3 {
      margin-bottom: $spacing-xs / 2;
    }
  }

  &-img {
    margin: $spacing-xs #{-$spacing-xs};
  }

  @media screen and (min-width: 568px) {
    &.block-description-img--r .description-img {
      margin: $spacing-xs 0 $spacing-xs #{-$spacing-xs};
    }

    &.block-description-img--l .description-img {
      margin: $spacing-xs #{-$spacing-xs} $spacing-xs 0;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: $spacing-l / 2;
    margin-right: 0;
    display: flex;
    align-items: center;

    // Description text with image, two columns
    &.block-description-img--r,
    &.block-description-img--l {
      .description-text {
        width: 40%;
      }

      .description-img {
        margin: 0;
        width: 60%;
      }
    }

    // Image on the right
    &.block-description-img--r .description-text {
      margin-right: $spacing-l;
    }

    // Image on the left
    &.block-description-img--l {
      margin-right: $spacing-l;
      margin-left: 0;

      .description-text {
        margin-left: $spacing-l;
        order: 2;
      }
    }
  
    // Full width description, two columns
    &.w-100 .description-text {
      margin: $spacing-xs / 2 $spacing-l $spacing-xs / 2 0;
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      grid-gap: 0 $spacing-l;

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

// enrichme
.enrichme .description-img {
  background-color: $enrichme-bg;
}

// bcito
.bcito .description-img {
  background-color: $bcito-bg;
}