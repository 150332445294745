// Two column layout
.browser-col-2 {
  margin-top: 1rem;

  .browser-mockup {
    margin-bottom: 1rem;

    &.cropped-center {
      padding: $spacing-l $spacing-xs 0;

      .browser-mockup-screen {
        padding-top: 968px / 1368px * 100%;
        box-shadow: -3px 34px 39px -1px rgba(0, 0, 0, 0.3);
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
  
    .browser-mockup {
      margin-bottom: 0;
      padding: 3rem;
      width: 50%;

      &.cropped-center:first-child {
        padding: $spacing-l $spacing-l / 2 0 $spacing-l;
      }
      &.cropped-center:nth-child(2) {
        padding: $spacing-l $spacing-l 0 $spacing-l / 2;
      }
    }
  }
}

// Custom BCITO (placing above `.browser-mockup` due to specificity issues, bleh).
// Adjusting the aspect ratio of browser screens
.bcito {
  .browser-mockup {
    background-color: $bcito-bg;

    &-screen {
      &.video,
      &.image {
        padding-top: 870px / 1368px * 100%;
      }
    }

    @media screen and (min-width: 568px) {
      padding: $spacing-l $spacing-l + 1rem;
    }
  }
}

.browser-mockup {
  padding: $spacing-l $spacing-xs;
  box-sizing: border-box;
  overflow: hidden;

  &-screen {
    position: relative;
    border-radius: 2px;
    box-shadow: $box-shadow-40;
    overflow: hidden;

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    video {
      width: 100%;
      height: auto;
    }

    // Aspect ratio for video
    &.video {
      padding-top: 680px / 1368px * 100%;
    }

    // Aspect ratio for image
    &.image {
      padding-top: 768px / 1368px * 100%;
    }
  }

  &.cropped-center {
    padding: $spacing-l $spacing-l / 2 0;

    .browser-mockup-screen {
      padding-top: 310px / 1368px * 100%;
    }
  }

  &.cropped-left {
    padding: $spacing-l 0 0 $spacing-l * 2;
  }

  &.cropped-right {
    padding: $spacing-l $spacing-l * 2 0 0;
  }

  &.cropped-left,
  &.cropped-right {
    .browser-mockup-screen {
      padding-top: 768px / 930px * 100%;
    }
  }

  @media screen and (min-width: 568px) {
    padding: $spacing-l;

    // Prevent image getting too big
    &.cropped-left,
    &.cropped-right {
      max-width: 60rem;
    }

    &.cropped-left {
      margin-left: auto;
    }

    &.cropped-right {
      margin-right: auto;
    }
  }

  @media screen and (min-width: 1024px) {
    &.cropped-center {
      padding: $spacing-l $spacing-l 0;
    }

    &.cropped-left {
      padding: $spacing-l 0 0 $spacing-l;
    }
  
    &.cropped-right {
      padding: $spacing-l $spacing-l 0 0;
    }

    &.cropped-left,
    &.cropped-right {
      margin: 0;
      max-width: none;
    }
  }
}

// enrichme
.enrichme .browser-mockup {
  background-color: $enrichme-bg;
}