:root {
  font-size: 10px;
  text-size-adjust: 100%;

  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
}

body {
  margin: 5.6rem 0 0;
  padding: 0;
  font: 400 1.6rem/1.6 'Gilroy-Light', sans-serif;
  letter-spacing: .016em;
  color: $black;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .App {
    margin-left: $spacing-l;
  }
}

main {
  display: block;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  transition: all .6s ease;
}

button,
a {
  &:focus {
    outline: 1px dotted lighten($navy, 40%);
  }
}

ul,
ol,
p {
  margin: 0;
  padding: 0;
  max-width: 60rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  margin-top: 0;
  font: normal #{$h1-fs}/1.4 'Zefani', sans-serif;
  letter-spacing: .1rem;
}

h2 {
  font-size: $h2-fs;
}

h3 {
  font: normal #{$h3-fs}/1.4 'Zefani', sans-serif;
  letter-spacing: .1rem;

}

h4 {
  font: normal #{$h4-fs}/1.4 'Gilroy-Light', sans-serif;
}

h5 {
  font-size: $h5-fs;
}

h6 {
  font-size: $h6-fs;
}