// About page
.About {
  min-height: 100vh;
}

.About__content {
  padding: $spacing-l $spacing-xs $spacing-xs;
}

.About__title {
  margin-bottom: $spacing-xs / 2;
}

.About__info {
  margin-bottom: $spacing-xs / 2;

  &:last-of-type {
    margin-bottom: $spacing-xs;
  }

  b {
    font-size: 2rem;
  }
}

.About__link {
  margin-bottom: $spacing-xs;
  display: inline-block;
}

.About__visual {
  background-color: #1e1f1f;
  width: 100%;
}

.About__img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .About__info {
    margin-bottom: $spacing-xs / 3;
  }
}

@media screen and (min-width: 1024px) {
  .AboutPage .Nav__btnbar {
    background-color: $gold;
    opacity: .8;
  }

  .About {
    display: flex;
  }

  .About__content {
    padding: $spacing-l $spacing-l $spacing-xs;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
  }

  .About__title {
    margin-bottom: $spacing-xs / 4;
    font-size: $h2-fs;
  }

  .About__info b {
    font-size: $h5-fs;
  }

  .About__visual {
    position: relative;
    width: 50vw;
    overflow: hidden;
  }

  .About__img {
    position: absolute;
    bottom: 0;
    left: -50px;
    width: auto;
    height: 100%;
  }
}

// CaseStudy - Full width image
.bcito .full-width-img {
  padding: $spacing-xs / 2;
  background-color: $bcito-bg;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 568px) {
    padding: $spacing-l / 2;
  }
}