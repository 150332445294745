// Layout
.container {
  margin: $spacing-xs;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    margin: $spacing-l / 2 $spacing-l;
  }
}

@media screen and (min-width: 1024px) {
  .mr5-m {
    margin-right: $spacing-l;
  }
}

.pt0 {
  padding-top: 0 !important; // Not proud of this ..
}


// Type
.title {
  letter-spacing: .3rem;
}

.subtitle {
  font-family: 'Brandon Grotesque', sans-serif;
  text-transform: uppercase;
  letter-spacing: .3rem;
}

// Elements
.btn {
  padding: .7em 1em .5em;
  color: $gold;
  border: 1px solid $gold;
  border-radius: 5em;
  text-align: center;

  i {
    margin-left: .2em;
    font-size: .9em;
  }
}

// Link
.link {
  color: $gold;
  border-bottom: 1px solid $gold;

  i {
    margin-left: .2em;
    font-size: .9em;
  }

  &:hover {
    opacity: .8;
  }
}

// Colours
.gold {
  color: $gold;
}

// Custom colours
.enrichme .title {
  color: $enrichme;
}

.bcito {
  .title {
    color: $bcito;
  }

  .block__visual {
    background-color: rgb(206,216,227);
    background: linear-gradient(90deg, rgba(206,216,227,1) 0%, rgba(201,210,224,1) 100%);
  }
}

.fwaste .title {
  color: $fwaste;
}

// Colour
.site-bgc {
  background-color: $site-bgc;
}