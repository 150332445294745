.header {
  height: 100vh;
}

.header__title {
  padding: 0 $spacing-xs;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 35%;
  color: $black;
}
  
.header__visual {
  position: relative;
  height: 65%;
  width: 100%;
  overflow: hidden;
}

.header__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .header__title {
    padding-left: 5rem;
  }
}
  
// iPad portrait
@media screen and (min-width: 768px) and (min-height: 1024px) {
  .header {
    height: 50vh;
  }
}
  
@media screen and (min-width: 1024px) {
  .header {
    height: 100vh;
  }

  .header__title {
    height: 45%;
  }

  .header__visual {
    height: 55%;
  }

  .bcito .header__img {
    margin: 0 auto;
    display: block;
    width: auto;
    height: 100%;
  }
}