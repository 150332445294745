.mobile-mockup {
  padding: $spacing-l / 2;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &-container {
    margin: $spacing-xs / 2;
    max-width: 30%;
    flex-grow: 1;
  }

  &-screen {
    position: relative;
    padding-top: 568px / 320px * 100%;
    border-radius: 2px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

  }
  @media screen and (max-width: 568px) {
    &-container {
      min-width: 40%;
    }
  }
}

// enrichme
.enrichme .mobile-mockup {
  background-color: $enrichme-bg;
}

// BCITO
.bcito .mobile-mockup {
  background-color: $bcito-bg;
}