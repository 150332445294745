.LandingPage {
  background-color: $site-bgc;
  box-sizing: border-box;
}

.LandingPage__wrapper {
  height: 100vh;
}

.LandingPage__content {
  padding: 0 $spacing-xs;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 35%;
  color: $black;
}

.LandingPage__transition {
  display: inline;
}

.LandingPage__info {
  font-size: $h4-fs;

  br {
    display: none;
  }
}

.LandingPage__visual {
  height: 65%;
  background-color: #1d1e1e;
  overflow: hidden;
}

.LandingPage__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (min-width: 568px) {
  .LandingPage {
    padding: $spacing-l / 2;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .LandingPage__wrapper {
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0 $spacing-xs;
    align-items: center;
    max-width: 88rem;
    width: 100%;
    height: 100%;
    overflow: hidden; // For Safari who renders height differently (this element (the parent) requires a fixed height rather than a percentage)
  }

  .LandingPage__content {
    padding: 0;
    display: block;
    height: auto;
    -ms-grid-row-align: center;
    z-index: 1;
  }

  .LandingPage__title {
    margin-right: -$spacing-l * 3;
    font-size: 7.5rem;
    line-height: 1.1;
    letter-spacing: .3rem;
    color: #b9c3c6;
  }

  .LandingPage__transition {
    display: block;

    &:last-child {
      margin-left: 4.5rem;
    }
  }

  .LandingPage__info {
    text-align: right;
    
    br {
      display: inline;
    }
  }

  .LandingPage__visual {
    height: 100%;
    -ms-grid-column: 2;
  }
}

@media screen and (min-width: 668px) {
  .LandingPage {
    padding: $spacing-l / 2 $spacing-l;
    height: calc(100vh - #{$spacing-l});
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) and (min-height: 400px) {
  .LandingPage {
    padding: 4rem $spacing-l / 2 4rem $spacing-l;
    height: 100vh;
  }

  .LandingPage__title {
    font-size: 10rem;
  }
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
  .LandingPage__wrapper {
    height: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .LandingPage {
    margin-right: $spacing-l;
    padding: 4rem $spacing-l;
  }
  
  .LandingPage__wrapper {
    max-height: 33rem;
  }

  .LandingPage__title {
    font-size: 7.5rem;
  }

  .LandingPage__info {
    font-size: $h5-fs;
  }
}

@media screen and (min-width: 1100px) {
  .LandingPage__wrapper {
    height: 100%;
    max-height: 50rem;
  }
}

@media screen and (min-width: 1400px) {
  .LandingPage__title {
    margin-left: 2rem;
    font-size: 9rem;
  }

  .LandingPage__info {
    font-size: 1.6rem;
  }
}

// Home page load transition
  .LandingPage__transition {
    overflow: hidden;
  }

  .LandingPage__transition-content {
    opacity: 0;
    transition: transform 1.2s cubic-bezier(0.7, 0, 0.3, 1), opacity 1s linear;
  }

  .LandingPage__info {
    opacity: 0;
    transition: opacity .5s linear;
  }

  .LandingPage__visual {
    opacity: 0;
    transition: opacity .5s linear;
  }

@media screen and (min-width: 568px) {
  .LandingPage__transition-content {
    display: block;
    transform: translateY(130px);
  }

  .LandingPage__transition-content--2 {
    transition-delay: .2s;
  }

  .LandingPage__info {
    transition-delay: 1s;
  }
}

.pageload-transition-appear-done {
  .LandingPage__transition,
  .LandingPage__transition-content {
    transform: translateY(0);
    opacity: 1;
  }

  .LandingPage__info,
  .LandingPage__visual {
    opacity: 1;
  }
}