.Social {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: $spacing-l;
  text-align: center;
  z-index: 4;
}
  
.Social__link {
  margin-bottom: 3.2rem;
  color: $gold;

  &:hover {
    transform: translateX(10px);
    opacity: .8;
  }
}

@media screen and (min-width: 1024px) {
  .Social {
    display: flex;
  }

  .Social__link {
    font-size: 1.125rem;
  }
}